
























































































import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import { mapMutations } from "vuex";
import { Dictionary } from "vue-router/types/router";

import { Timer } from "@/utils/Timer";
import WaterDeficitChart from "@/pages/components/charts/waterDeficitChart.vue";
import SourceDistributionChart from "@/pages/components/charts/sourceDistributionChart.vue";
import CO2EmissionChart from "@/pages/components/charts/CO2EmissionChart.vue";
import HidroEconomicChart from "@/pages/components/charts/hidroEconomicChart.vue";
import PlanConfiguration from "@/pages/components/charts/planConfiguration.vue";
import PlannerTable from "@/pages/components/tables/plannerTable.vue";
import {
    PlannerPlotData,
    PlannerPlotDataResponse,
    PlannerTableData,
    PlannerConfigData,
    PlannerTableDataResponse,
    PlannerConfigDataResponse,
    ResponseDownloadData
} from "@/interfaces";
import * as types from "@/store/types";

@Component({
    components: {
        WaterDeficitChart: () =>
            import("@/pages/components/charts/waterDeficitChart.vue"),
        SourceDistributionChart: () =>
            import("@/pages/components/charts/sourceDistributionChart.vue"),
        CO2EmissionChart: () =>
            import("@/pages/components/charts/CO2EmissionChart.vue"),
        HidroEconomicChart: () =>
            import("@/pages/components/charts/hidroEconomicChart.vue"),
        PlannerTable: () =>
            import("@/pages/components/tables/plannerTable.vue"),
        ScenarioAnalysisDiaolog: () =>
            import("@/components/dialogs/ScenarioAnalysisDialog.vue"),
        ScenarioAnalysisForm: () =>
            import("@/components/forms/ScenarioAnalysisForm.vue"),
        PlanConfiguration: () =>
            import("@/pages/components/charts/planConfiguration.vue")
    },
    methods: {
        ...mapMutations({
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE,
            setProgressBar: types.MUTATE_APP_PROGRESSBAR
        })
    }
})
export default class Planner extends Vue {
    @Ref("chartDeficit") readonly chartDeficit!: WaterDeficitChart;
    @Ref("chartSources") readonly chartSources!: SourceDistributionChart;
    @Ref("chartEmission") readonly chartEmission!: CO2EmissionChart;
    @Ref("chartEconomic") readonly chartEconomic!: HidroEconomicChart;
    @Ref("chartPlanConf") readonly chartPlanConf!: PlanConfiguration;
    @Ref("plannerTable") readonly plannerTable!: PlannerTable;

    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    setProgressBar!: (state: boolean) => void;

    items: Dictionary<string>[] = [
        {
            text: "Murcia",
            href: "#/l4/dashboard"
        }
    ];

    selectedCategory: string | undefined = "";
    selectedUD: string | undefined = "";
    hiddeTable = false;
    dialog = false;
    daily = true;
    planLength = false;
    planLabel = this.$t("planner.shortplan");
    loadingProgress = 0;
    timer!: Timer;

    skeleton = true;

    plotData: PlannerPlotData = {
        date: [],
        demand: [],
        planned: [],
        incertLow: [],
        incertHigh: [],
        superficial: [],
        subterranea: [],
        reutilizada: [],
        trasvase: [],
        desalada: [],
        CO2: [],
        economic: []
    };

    planData: PlannerConfigData = {
        CO2impact: 0,
        waterDeficit: 0,
        economicImpact: 0,
        superficial: 0,
        subterranea: 0,
        reutilizada: 0,
        trasvase: 0,
        desalada: 0,
        start: "",
        end: "",
        creationDate: ""
    };

    tableData: PlannerTableData[] = [];

    @Watch("$route.params")
    async onRouteUpdate() {
        this.clearData();
        this.updateData();
    }

    mounted() {
        this.updateData();
        this.timer = new Timer({
            timeout: 20000,
            immediate: true
        });
        this.timer.on("tick", async () => {
            this.checkPlanGenerated();
        });
        const planId = localStorage.getItem("plannerRuning");
        if (planId != undefined) {
            this.timer.start();
        }
    }

    destroyed() {
        this.timer.stop();
    }

    updateData() {
        this.selectedCategory = this.$route.params.type;
        this.selectedUD = this.$route.params.ud;
        this.hiddeTable =
            this.selectedCategory != undefined && this.selectedUD != undefined;
        this.fetchPlotData();
        if (!this.hiddeTable) {
            this.fetchTableData();
        }
        this.fetchPlanConfigData();
        this.updateBreadcrumbs();
    }

    fetchPlotData() {
        this.skeleton = true;
        this.setProgressBar(true);
        this.loadingProgress += 1;
        this.$api
            .getPlannerPlotData<PlannerPlotDataResponse>(
                this.selectedCategory,
                this.selectedUD,
                this.planLength,
                this.daily
            )
            .then(response => {
                if (response.status == 200) {
                    this.skeleton = false;
                    this.plotData = response.data;
                    this.loadingProgress -= 1;
                    if (this.loadingProgress == 0) this.setProgressBar(false);
                }
                if (response.status == 202) {
                    this.loadingProgress = 0;
                    this.setProgressBar(false);
                    this.showMsg(String(this.$t("planner.noPlan")));
                }
            })
            .catch(error => {
                if (error instanceof Error) {
                    this.loadingProgress -= 1;
                    if (this.loadingProgress == 0) this.setProgressBar(false);
                    this.skeleton = true;
                    this.showMsg(`${this.$t("netError")}`);
                    this.plotData = {
                        date: [],
                        demand: [],
                        planned: [],
                        incertLow: [],
                        incertHigh: [],
                        superficial: [],
                        subterranea: [],
                        reutilizada: [],
                        trasvase: [],
                        desalada: [],
                        CO2: [],
                        economic: []
                    };
                }
            });
    }

    fetchTableData() {
        this.setProgressBar(true);
        this.loadingProgress += 1;
        this.$api
            .getPlannerTableData<PlannerTableDataResponse>(
                this.selectedCategory,
                this.planLength,
                this.daily
            )
            .then(response => {
                if (response.status == 200) {
                    this.tableData = response.data;
                    this.loadingProgress -= 1;
                    if (this.loadingProgress == 0) this.setProgressBar(false);
                }
                if (response.status == 202) {
                    this.loadingProgress = 0;
                    this.setProgressBar(false);
                    this.showMsg(String(this.$t("planner.noPlan")));
                }
            })
            .catch(error => {
                if (error instanceof Error) {
                    this.loadingProgress -= 1;
                    if (this.loadingProgress == 0) this.setProgressBar(false);
                    this.showMsg(`${this.$t("netError")}`);
                    this.tableData = [];
                }
            });
    }

    fetchPlanConfigData() {
        this.setProgressBar(true);
        this.loadingProgress += 1;
        this.$api
            .getPlanConfigData<PlannerConfigDataResponse>(
                this.planLength,
                this.daily
            )
            .then(response => {
                if (response.status == 200) {
                    this.planData = response.data;
                    this.loadingProgress -= 1;
                    if (this.loadingProgress == 0) this.setProgressBar(false);
                }
                if (response.status == 202) {
                    this.loadingProgress = 0;
                    this.setProgressBar(false);
                    this.showMsg(String(this.$t("planner.noPlan")));
                }
            })
            .catch(error => {
                if (error instanceof Error) {
                    this.loadingProgress -= 1;
                    if (this.loadingProgress == 0) this.setProgressBar(false);
                    this.showMsg(`${this.$t("netError")}`);
                    this.planData = {
                        CO2impact: 0,
                        waterDeficit: 0,
                        economicImpact: 0,
                        superficial: 0,
                        subterranea: 0,
                        reutilizada: 0,
                        trasvase: 0,
                        desalada: 0,
                        start: "",
                        end: "",
                        creationDate: ""
                    };
                }
            });
    }

    updateBreadcrumbs() {
        this.items = [
            {
                text: "Murcia",
                href: "#/l4/dashboard"
            }
        ];
        if (this.selectedCategory) {
            this.items.push({
                text: this.selectedCategory,
                href: `#/l4/dashboard/${this.selectedCategory}`
            });
        }
        if (this.selectedUD) {
            this.items.push({
                text: this.selectedUD,
                href: `#/l4/dashboard/${this.selectedCategory}/${this.selectedUD}`
            });
        }
    }

    plannerRuning(hash: string) {
        localStorage.setItem("plannerRuning", hash);
        this.timer.start();
    }

    checkPlanGenerated() {
        const planId = localStorage.getItem("plannerRuning");
        if (planId == undefined) {
            this.timer.stop();
            return;
        }
        this.$api
            .checkPlanGenerated<Response>(planId)
            .then(response => {
                if (response.status == 200) {
                    this.showMsg(String(this.$t("planner.planGenerated")));
                    localStorage.removeItem("plannerRuning");
                    this.updateData();
                }
            })
            .catch(error => {
                if (error instanceof Error) {
                    this.showMsg(`${this.$t("netError")}`);
                    localStorage.removeItem("plannerRuning");
                }
            });
    }

    clearData() {
        this.loadingProgress = 0;
        this.setProgressBar(false);
        this.plotData = {
            date: [],
            demand: [],
            planned: [],
            incertLow: [],
            incertHigh: [],
            superficial: [],
            subterranea: [],
            reutilizada: [],
            trasvase: [],
            desalada: [],
            CO2: [],
            economic: []
        };
        this.planData = {
            CO2impact: 0,
            waterDeficit: 0,
            economicImpact: 0,
            superficial: 0,
            subterranea: 0,
            reutilizada: 0,
            trasvase: 0,
            desalada: 0,
            start: "",
            end: "",
            creationDate: ""
        };
        this.tableData = [];
    }

    showMsg(msg: string) {
        this.setInfoMessage({ shown: true, text: msg });
    }

    openScenarioAnalysisDialog() {
        const planId = localStorage.getItem("plannerRuning");
        if (planId != undefined) {
            this.showMsg(String(this.$t("planner.planGenerating")));
            return;
        }
        this.dialog = true;
    }

    switchUpdate() {
        if (this.planLength == false) {
            this.planLabel = this.$t("planner.shortplan");
            this.daily = true;
        } else {
            this.planLabel = this.$t("planner.longplan");
            this.daily = false;
        }
        this.clearData();
        this.updateData();
    }

    checkboxUpdate() {
        if (this.planLength) {
            this.clearData();
            this.updateData();
        }
    }

    async downloadCSV() {
        const response = await this.$api.downloadPlannerData<
            ResponseDownloadData
        >(this.planLength, this.daily);
        if (!response) return;
        if (response.status == 204) {
            this.showMsg(String(this.$t("NoDataToDownload")));
            return;
        }
        let planType = "short";
        if (this.planLength) {
            planType = "long";
        }
        let step = "daily";
        if (this.planLength && this.daily == false) {
            step = "monthly";
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `plan_${planType}_term_${step}_step.csv`
        );
        document.body.appendChild(link);
        link.click();
    }
}
